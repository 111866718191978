import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Button from '../Basic/Button';
import Row from '../Basic/Row';
import Col from '../Basic/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const PricingCard = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 4px 4px 8px rgb(39 39 62 / 12%);
  padding: 20px;

  height: ${({ left, right }) => left || right ? 'calc(100% - 20px)' : 'auto'};
  display: flex;
  flex-direction: column;
  justify-content: ${({ right }) => right ? 'space-between' : 'flex-start'};

  h3{
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;

    span{
      display: block;
      font-size: 14px;
      font-weight: 600;
    }
  }
  h4{
    font-weight: 500;
  }
`;

const PricingCardList = styled.ul`

`;

const PricingCardListItem = styled.li`
  width: 100%;
  display: flex;
  position: relative;
  text-align: left;
  align-items: center;
  padding: 0.3rem 0rem;
  justify-content: flex-start;
  text-decoration: none;
  color: ${({ mode, theme }) =>
    mode === 'secondary' ? theme.neutralColor.textSecondary : theme.neutralColor.textPrimary};
`;

const PricingCardListItemIcon = styled.div`
  min-width: 25px;
  flex-shrink: 0;
  color: ${({ mode, theme }) =>
    mode === 'secondary' ? theme.accentColor.primary : theme.accentColor.primary};
`;

const PricingCardListItemText = styled.div`
  font-size: 0.875rem;
`;

const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding: 4px 10px;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
`;

const FieldGroupSeparator = styled.div`
  width: 100%;
  height: 2px;
  background-color: #E9E9E9;
`;

const FieldTitle = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
      margin: 5px 0px;
`;

const SummaryRow = styled.div`
  margin: 10px 0px;
`;

const SummaryPrice = styled.span`
  font-size: 28px;
  font-weight: 600;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
`;

const SummaryPriceGross = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.neutralColor.textPrimary};
`;

const RadioButtons = styled.div`
  display: flex;
`;

const TextInput = styled.input.attrs({
  type: 'text'
})`
  border: 2px solid #EFEFEF;
  border-radius: 3px;
  padding: 3px 0px 3px 10px;
  font-size: 14px;
  width: 80px;
  margin: 5px 0px;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
  appearance: none;
}
`;

const RadioButtonsInput = styled.input.attrs({
  type: 'radio'
})`
  opacity: 0;
  position: fixed;
  width: 0;

  :checked + label {
    background-color: ${({ theme }) => theme.accentColor.primary};
    border-color: ${({ theme }) => theme.accentColor.primary};
    color: #fff;
  }

  :focus + label {
    box-shadow: 0px 0px 10px 2px ${({ theme }) => theme.accentColor.primary};
  }
`;

const RadioButtonsLabel = styled.label`
  display: inline-block;
  background-color: #fff;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  margin: 10px 10px 10px 0px;
  border:  2px solid #EFEFEF;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :hover{
    background-color: ${({ theme }) => theme.accentColor.primary};
    color: #fff;
  }
`;

const PriceCalculator = () => {
  const [pageData, setPageData] = useState({
    pages: {
      value: 5,
      price: 80
    },
    shop: {
      value: false,
      price: 800
    },
    googleIntegration: {
      value: false,
      price: 120
    },
    seo: {
      value: false,
      price: 200
    },
    hostingAndDomain: {
      value: false,
      price: 100
    },
    map: {
      value: false,
      price: 150
    },
    socialMedia: {
      value: false,
      price: 120
    },
    newsletter: {
      value: false,
      price: 120
    },
    designProject: {
      value: false,
      price: 800
    }
  });

  const [price, setPrice] = useState(899);

  useEffect(() => {
    let basePrice = 899;

    for (const property in pageData) {
      if (property === 'pages') {
        basePrice = basePrice + (pageData[property].value > 5 ? pageData[property].value - 5 : 0) * pageData[property].price;
      }
      else {
        if (pageData[property].value === true) {
          basePrice = basePrice + pageData[property].price;
        }
      }
    }
    setPrice(basePrice);

  }, [pageData]);

  const formPreventDefault = (e) => {
    e.preventDefault();
  }

  const handleChange = (e) => {
    const value = e.target.value;
    const element = e.target.name;
    const updatedElement = pageData[element];

    if (element === 'pages') {
      if (Number(value) > 30) {
        updatedElement.value = 29;
      } else {
        updatedElement.value = Number(value);
      }
      setPageData({
        ...pageData,
        [e.target.name]: updatedElement
      });
    } else if (element === 'checkbox') {
      updatedElement.value = !updatedElement.value;
      setPageData({
        ...pageData,
        [e.target.name]: updatedElement
      });
    }
    else if (
      element === 'shop' ||
      element === 'googleIntegration' ||
      element === 'newsletter' ||
      element === 'socialMedia' ||
      element === 'seo' ||
      element === 'map' ||
      element === 'hostingAndDomain' ||
      element === 'designProject'
    ) {
      updatedElement.value = !updatedElement.value;
      setPageData({
        ...pageData,
        [e.target.name]: updatedElement
      });
    }
  }

  return (
    <Row style={{ justifyContent: 'center', marginTop: '30px' }}>
      <Col md={4} style={{ zIndex: '1', marginTop: '20px' }}>
        <PricingCard left>
          <h3>
            Podstawowe fukcje <span> zawarte w każdym pakiecie</span>
          </h3>
          <PricingCardList>
            <PricingCardListItem>
              <PricingCardListItemIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PricingCardListItemIcon>
              <PricingCardListItemText>Dostosowanie do urządzeń mobilnych</PricingCardListItemText>
            </PricingCardListItem>
            <PricingCardListItem>
              <PricingCardListItemIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PricingCardListItemIcon>
              <PricingCardListItemText>Formularz kontaktowy</PricingCardListItemText>
            </PricingCardListItem>
            <PricingCardListItem>
              <PricingCardListItemIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PricingCardListItemIcon>
              <PricingCardListItemText>Galeria zdjęć</PricingCardListItemText>
            </PricingCardListItem>
            <PricingCardListItem>
              <PricingCardListItemIcon>
                <FontAwesomeIcon icon={faCheckCircle} />
              </PricingCardListItemIcon>
              <PricingCardListItemText>Blog</PricingCardListItemText>
            </PricingCardListItem>
          </PricingCardList>
          <PricingCardListItem>
            <PricingCardListItemIcon>
              <FontAwesomeIcon icon={faCheckCircle} />
            </PricingCardListItemIcon>
            <PricingCardListItemText>CMS (system zarządzania treścią)</PricingCardListItemText>
          </PricingCardListItem>
          <PricingCardListItem>
            <PricingCardListItemIcon>
              <FontAwesomeIcon icon={faCheckCircle} />
            </PricingCardListItemIcon>
            <PricingCardListItemText>Informacja o ciasteczkach</PricingCardListItemText>
          </PricingCardListItem>
          <PricingCardListItem>
            <PricingCardListItemIcon>
              <FontAwesomeIcon icon={faCheckCircle} />
            </PricingCardListItemIcon>
            <PricingCardListItemText>Uruchomienie strony</PricingCardListItemText>
          </PricingCardListItem>
        </PricingCard>
      </Col>
      <Col md={4} style={{ margin: '0 -40px', zIndex: '10' }}>
        <PricingCard>
          <h3>
            Wybierz funkcje
          </h3>
          <form onSubmit={formPreventDefault}>
            <FieldGroup>
              <FieldTitle>
                Liczba podstron (powyżej 5):
              </FieldTitle>
              <TextInput pattern="[0-9]*" name='pages' value={pageData.pages.value} onChange={handleChange} />
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Zgłoszenie do wyszukiwarki Google, Połączenie z Google Analytics: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="googleIntegrationNo"
                  name="googleIntegration"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.googleIntegration.value === false}
                />
                <RadioButtonsLabel htmlFor="googleIntegrationNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="googleIntegrationYes"
                  name="googleIntegration"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.googleIntegration.value === true}
                />
                <RadioButtonsLabel htmlFor="googleIntegrationYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Konfiguracja SEO: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="seoNo"
                  name="seo"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.seo.value === false}
                />
                <RadioButtonsLabel htmlFor="seoNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="seoYes"
                  name="seo"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.seo.value === true}
                />
                <RadioButtonsLabel htmlFor="seoYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Pomoc w wyborze hostigu i zakupie domeny: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="hostingAndDomainNo"
                  name="hostingAndDomain"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.hostingAndDomain.value === false}
                />
                <RadioButtonsLabel htmlFor="hostingAndDomainNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="hostingAndDomainYes"
                  name="hostingAndDomain"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.hostingAndDomain.value === true}
                />
                <RadioButtonsLabel htmlFor="hostingAndDomainYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Mapa z lokalizacją firmy: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="mapNo"
                  name="map"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.map.value === false}
                />
                <RadioButtonsLabel htmlFor="mapNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="mapYes"
                  name="map"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.map.value === true}
                />
                <RadioButtonsLabel htmlFor="mapYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Integracja z Social Media: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="socialMediaNo"
                  name="socialMedia"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.socialMedia.value === false}
                />
                <RadioButtonsLabel htmlFor="socialMediaNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="socialMediaYes"
                  name="socialMedia"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.socialMedia.value === true}
                />
                <RadioButtonsLabel htmlFor="socialMediaYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Newsletter: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="newsletterNo"
                  name="newsletter"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.newsletter.value === false}
                />
                <RadioButtonsLabel htmlFor="newsletterNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="newsletterYes"
                  name="newsletter"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.newsletter.value === true}
                />
                <RadioButtonsLabel htmlFor="newsletterYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle> Sklep Internetowy: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="shopNo"
                  name="shop"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.shop.value === false}
                />
                <RadioButtonsLabel htmlFor="shopNo">Nie</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="shopYes"
                  name="shop"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.shop.value === true}
                />
                <RadioButtonsLabel htmlFor="shopYes">Tak</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup style={{ flexWrap: 'wrap' }}>
              <FieldTitle style={{ marginTop: '7px' }}> Projekt graficzny: </FieldTitle>
              <RadioButtons>
                <RadioButtonsInput
                  type="radio"
                  id="designProjectNo"
                  name="designProject"
                  value="false"
                  onChange={handleChange}
                  checked={pageData.designProject.value === false}
                />
                <RadioButtonsLabel htmlFor="designProjectNo">Podstawowy</RadioButtonsLabel>
                <RadioButtonsInput
                  type="radio"
                  id="designProjectYes"
                  name="designProject"
                  value="true"
                  onChange={handleChange}
                  checked={pageData.designProject.value === true}
                />
                <RadioButtonsLabel htmlFor="designProjectYes">Personalizowany</RadioButtonsLabel>
              </RadioButtons>
            </FieldGroup>
            <FieldGroupSeparator />
            <FieldGroup>
              <FieldTitle style={{ marginTop: '7px' }}> Aktualizacje i zarządzanie: </FieldTitle>
              <FieldTitle style={{ marginTop: '5px' }}> Wycena indywidualna </FieldTitle>
            </FieldGroup>
          </form>
        </PricingCard>
      </Col>
      <Col md={4} style={{ zIndex: '1', marginTop: '20px' }}>
        <PricingCard style={{ paddingLeft: '30px' }} right>
          <div>
            <h3>
              Twój pakiet
            </h3>
            <h4>Wybrane funkcje:</h4>
            <PricingCardList>
              <PricingCardListItem>
                <PricingCardListItemIcon>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </PricingCardListItemIcon>
                <PricingCardListItemText> Liczba podstron: {pageData.pages.value} </PricingCardListItemText>
              </PricingCardListItem>

              {pageData.shop.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText> Sklep Internetowy</PricingCardListItemText>
                </PricingCardListItem>
              }
              {pageData.googleIntegration.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText> Integracja z narzędziami wyszukiwarki Google</PricingCardListItemText>
                </PricingCardListItem>
              }
              {pageData.newsletter.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText> Newsletter</PricingCardListItemText>
                </PricingCardListItem>
              }
              {pageData.seo.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText>Konfiguracja SEO</PricingCardListItemText>
                </PricingCardListItem>
              }
              {pageData.map.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText>Mapa z lokalizacją firmy</PricingCardListItemText>
                </PricingCardListItem>
              }
              {pageData.socialMedia.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText>Integracja z Social Media</PricingCardListItemText>
                </PricingCardListItem>
              }
              {pageData.hostingAndDomain.value === true &&
                <PricingCardListItem>
                  <PricingCardListItemIcon>
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </PricingCardListItemIcon>
                  <PricingCardListItemText> Pomoc w wyborze hostingu i domeny</PricingCardListItemText>
                </PricingCardListItem>
              }
              <PricingCardListItem>
                <PricingCardListItemIcon>
                  <FontAwesomeIcon icon={faCheckCircle} />
                </PricingCardListItemIcon>
                <PricingCardListItemText>{pageData.designProject.value === true ? 'Personalizowany' : 'Podstawowy'}  projekt graficzny </PricingCardListItemText>
              </PricingCardListItem>
            </PricingCardList>
          </div>
          <div style={{ textAlign: 'center' }}>
            <h4>Już od:</h4>
            <SummaryRow>
              <SummaryPrice>{price.toFixed(2)} zł <span style={{ fontSize: '14px', fontWeight: '400' }}>netto</span></SummaryPrice>
            </SummaryRow>
            <div>
              <SummaryPriceGross>Do ceny należy doliczyć podatek VAT</SummaryPriceGross>
              {/* <SummaryPriceGross>{priceGross.toFixed(2)} zł brutto</SummaryPriceGross> */}
            </div>
            <div>
              <Button round="5px" margin="15px 0px" fontSize="12px">
                ZADZWOŃ 696 476 519
              </Button>
            </div>
          </div>
        </PricingCard>
      </Col>
    </Row>
    // <Row style={{ justifyContent: 'center', marginTop: '30px' }}>
    //   <Col md={4} style={{ zIndex: '1', marginTop: '20px' }}>
    //     <PricingCard left>
    //       <h3>
    //         Podstawowe fukcje <span> zawarte w każdym pakiecie</span>
    //       </h3>
    //       <PricingCardList>
    //         <PricingCardListItem>
    //           <PricingCardListItemIcon>
    //             <FontAwesomeIcon icon={faCheckCircle} />
    //           </PricingCardListItemIcon>
    //           <PricingCardListItemText>Zgłoszenie do wyszukiwarki Google</PricingCardListItemText>
    //         </PricingCardListItem>
    //         <PricingCardListItem>
    //           <PricingCardListItemIcon>
    //             <FontAwesomeIcon icon={faCheckCircle} />
    //           </PricingCardListItemIcon>
    //           <PricingCardListItemText>Dostosowanie do urządzeń mobilnych</PricingCardListItemText>
    //         </PricingCardListItem>
    //         <PricingCardListItem>
    //           <PricingCardListItemIcon>
    //             <FontAwesomeIcon icon={faCheckCircle} />
    //           </PricingCardListItemIcon>
    //           <PricingCardListItemText>Informacja o ciasteczkach</PricingCardListItemText>
    //         </PricingCardListItem>
    //         <PricingCardListItem>
    //           <PricingCardListItemIcon>
    //             <FontAwesomeIcon icon={faCheckCircle} />
    //           </PricingCardListItemIcon>
    //           <PricingCardListItemText>Połączenie z Google Analytics</PricingCardListItemText>
    //         </PricingCardListItem>
    //         <PricingCardListItem>
    //           <PricingCardListItemIcon>
    //             <FontAwesomeIcon icon={faCheckCircle} />
    //           </PricingCardListItemIcon>
    //           <PricingCardListItemText>Pomoc w wyborze hostingu</PricingCardListItemText>
    //         </PricingCardListItem>
    //       </PricingCardList>
    //       <PricingCardListItem>
    //         <PricingCardListItemIcon>
    //           <FontAwesomeIcon icon={faCheckCircle} />
    //         </PricingCardListItemIcon>
    //         <PricingCardListItemText>Pomoc w zakupie domeny</PricingCardListItemText>
    //       </PricingCardListItem>
    //       <PricingCardListItem>
    //         <PricingCardListItemIcon>
    //           <FontAwesomeIcon icon={faCheckCircle} />
    //         </PricingCardListItemIcon>
    //         <PricingCardListItemText>Uruchomienie strony</PricingCardListItemText>
    //       </PricingCardListItem>
    //     </PricingCard>
    //   </Col>
    //   <Col md={4} style={{ margin: '0 -40px', zIndex: '10' }}>
    //     <PricingCard>
    //       <h3>
    //         Wybierz funkcje
    //       </h3>
    //       <form onSubmit={formPreventDefault}>
    //         <FieldGroup>
    //           <FieldTitle>
    //             Liczba podstron:
    //           </FieldTitle>
    //           <TextInput pattern="[0-9]*" name='pages' value={pageData.pages.value} onChange={handleChange} />
    //         </FieldGroup>
    //         <FieldGroupSeparator />
    //         <FieldGroup>
    //           <FieldTitle> Moduł bloga: </FieldTitle>
    //           <RadioButtons>
    //             <RadioButtonsInput
    //               type="radio"
    //               id="blogNo"
    //               name="blog"
    //               value="false"
    //               onChange={handleChange}
    //               checked={pageData.blog.value === false}
    //             />
    //             <RadioButtonsLabel htmlFor="blogNo">Nie</RadioButtonsLabel>
    //             <RadioButtonsInput
    //               type="radio"
    //               id="blogYes"
    //               name="blog"
    //               value="true"
    //               onChange={handleChange}
    //               checked={pageData.blog.value === true}
    //             />
    //             <RadioButtonsLabel htmlFor="blogYes">Tak</RadioButtonsLabel>
    //           </RadioButtons>
    //         </FieldGroup>
    //         <FieldGroupSeparator />
    //         <FieldGroup>
    //           <FieldTitle> Sklep Internetowy: </FieldTitle>
    //           <RadioButtons>
    //             <RadioButtonsInput
    //               type="radio"
    //               id="wordpressNo"
    //               name="wordpresscms"
    //               value="false"
    //               onChange={handleChange}
    //               checked={pageData.wordpresscms.value === false}
    //             />
    //             <RadioButtonsLabel htmlFor="wordpressNo">Nie</RadioButtonsLabel>
    //             <RadioButtonsInput
    //               type="radio"
    //               id="wordpressYes"
    //               name="wordpresscms"
    //               value="true"
    //               onChange={handleChange}
    //               checked={pageData.wordpresscms.value === true}
    //             />
    //             <RadioButtonsLabel htmlFor="wordpressYes">Tak</RadioButtonsLabel>
    //           </RadioButtons>
    //         </FieldGroup>
    //         <FieldGroupSeparator />
    //         <FieldGroup>
    //           <FieldTitle style={{ marginTop: '7px' }}> Projekt graficzny: </FieldTitle>
    //           <RadioButtons>
    //             <RadioButtonsInput
    //               type="radio"
    //               id="designProjectNo"
    //               name="designProject"
    //               value="false"
    //               onChange={handleChange}
    //               checked={pageData.designProject.value === false}
    //             />
    //             <RadioButtonsLabel htmlFor="designProjectNo">Podstawowy</RadioButtonsLabel>
    //             <RadioButtonsInput
    //               type="radio"
    //               id="designProjectYes"
    //               name="designProject"
    //               value="true"
    //               onChange={handleChange}
    //               checked={pageData.designProject.value === true}
    //             />
    //             <RadioButtonsLabel htmlFor="designProjectYes">Personalizowany</RadioButtonsLabel>
    //           </RadioButtons>
    //         </FieldGroup>
    //       </form>
    //     </PricingCard>
    //   </Col>
    //   <Col md={4} style={{ zIndex: '1', marginTop: '20px' }}>
    //     <PricingCard style={{ paddingLeft: '30px' }} right>
    //       <div>
    //         <h3>
    //           Twój pakiet
    //         </h3>
    //         <h4>Wybrane funkcje:</h4>
    //         <PricingCardList>
    //           <PricingCardListItem>
    //             <PricingCardListItemIcon>
    //               <FontAwesomeIcon icon={faCheckCircle} />
    //             </PricingCardListItemIcon>
    //             <PricingCardListItemText> Liczba stron: {pageData.pages.value} </PricingCardListItemText>
    //           </PricingCardListItem>

    //           {pageData.blog.value === true &&
    //             <PricingCardListItem>
    //               <PricingCardListItemIcon>
    //                 <FontAwesomeIcon icon={faCheckCircle} />
    //               </PricingCardListItemIcon>
    //               <PricingCardListItemText> Moduł Bloga </PricingCardListItemText>
    //             </PricingCardListItem>
    //           }

    //           {pageData.wordpresscms.value === true &&
    //             <PricingCardListItem>
    //               <PricingCardListItemIcon>
    //                 <FontAwesomeIcon icon={faCheckCircle} />
    //               </PricingCardListItemIcon>
    //               <PricingCardListItemText> Sklep Internetowy</PricingCardListItemText>
    //             </PricingCardListItem>
    //           }
    //           <PricingCardListItem>
    //             <PricingCardListItemIcon>
    //               <FontAwesomeIcon icon={faCheckCircle} />
    //             </PricingCardListItemIcon>
    //             <PricingCardListItemText>{pageData.designProject.value === true ? 'Personalizowany' : 'Podstawowy'}  projekt graficzny </PricingCardListItemText>
    //           </PricingCardListItem>
    //         </PricingCardList>
    //       </div>
    //       <div style={{ textAlign: 'center' }}>
    //         <h4>Już od:</h4>
    //         <SummaryRow>
    //           <SummaryPrice>{price.toFixed(2)} zł <span style={{ fontSize: '14px', fontWeight: '400' }}>netto</span></SummaryPrice>
    //         </SummaryRow>
    //         <div>
    //           <SummaryPriceGross>Do ceny należy doliczyć podatek VAT</SummaryPriceGross>
    //           {/* <SummaryPriceGross>{priceGross.toFixed(2)} zł brutto</SummaryPriceGross> */}
    //         </div>
    //         <div>
    //           <Button round="5px" margin="15px 0px" fontSize="12px">
    //             ZADZWOŃ 696 476 519
    //           </Button>
    //         </div>
    //       </div>
    //     </PricingCard>
    //   </Col>
    // </Row>
  );
};

export default PriceCalculator;
